import React from "react"
import { Link } from "gatsby"

const Gallery = (props) => {
    const items = props.gallery;
    return(
        <>
            {
                items.map((gallery, index) => {
                    return(
                        <div className="gallery-block fade-in" key={gallery.post_id} >
                            <div className="gallery-inner">
                                <a className="gallery-img" onClick={() => props.handleClick(gallery.post_id)}>
                                    <figure>
                                        <div className="img" style={{ backgroundImage: `url(${gallery.featured_image})` }}></div> 
                                    </figure>
                                </a>
                            </div>
                        </div>
                    )
                })
            }
        </>
    );
}
export default Gallery;