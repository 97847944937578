import React , {Component} from "react"
import { withPrefix } from 'gatsby'

import Carousel, { Modal, ModalGateway } from 'react-images';
import axios from 'axios';

import Layout from "../components/layout"
import SEO from "../components/seo"

import Loader from "../components/loader"
import Banner from "../components/banner-inner"
import GalleryList from "../components/gallery"
import GalleryDetail from "../components/gallery-list"

import {API_EN} from '../utils/config';

import steering from "../images/steering.png";


import "../styles/page.scss"



class Gallery extends Component {
    _isMounted = false;
    constructor(props){
        super(props);
        this.toggleModal = this.toggleModal.bind(this);
        this.loadMore = this.loadMore.bind(this);
        this.showGallery = this.showGallery.bind(this);
        this.bakToGallery = this.bakToGallery.bind(this);

        this.state = { 
            modalIsOpen: false, 
            selectedIndex: 0, 
            items: [],
            visible: 6,
            error: false,
            gallery : null,
            activeGallery : null,
        };

        this.fetchData = this.fetchData.bind(this);
    }
    componentDidMount(){
        this._isMounted = true;
        // this.setState({
        //     items: galleryArr
        // });

        this.fetchData(['gallery']);
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    fetchData(pages){
        const get = endpoint => axios.get(`${API_EN}/${endpoint}`);
        
        return Promise.all(
            pages.map(async page => {
                const { data: item } = await get(`${page}`);
                return item.data ? item.data : null;  
            })
        ).then(res => {
            if(!res[0]) {
                this.props.navigate(withPrefix(`/404`));
            }else{
                if (this._isMounted) {
                    res[0].map((gal, index) => {
                        gal.gallery.map((galImg, index, galArr) => {
                            galArr[index] = { 'src' : galImg }
                        })
                    })
                    this.setState({gallery : res})
                }
            }
        }); 
    }

    loadMore(e) {
        e.preventDefault();
        this.setState((prev) => {
            return {visible: prev.visible + 6};
        });
    }
    
    toggleModal = (index) => {
        this.setState(state => ({ 
            modalIsOpen: !state.modalIsOpen, 
            selectedIndex: typeof index == 'number' ? index : 0 
        }));
    }

    showGallery = (id) => {
        this.setState({
            activeGallery : id
        })
    }

    bakToGallery = (e) => {
        this.setState({
            activeGallery: null
        })
    }
    render(){
        const { modalIsOpen, selectedIndex } = this.state;

        if(!this.state.gallery) return <Loader />;
        const activId = this.state.activeGallery;
        const items = !activId  ? 
                        this.state.gallery[0] : 
                        this.state.gallery[0].filter(function(gal) {
                            return gal.post_id === activId;
                        });

        return (
            <Layout> 
                <SEO title="Gallery" />
                <Banner title={activId ? items[0].post_title  : "Gallery"} handleClick={this.bakToGallery} goBack={activId ? true : false }/>
                <section className="page-section section-gallery">
                    <div className="container">
                        <div className="section-block">
                            <div className="line __right"></div>
                            <figure className="placeholder"><img src={steering}/></figure>
                            <div className="gallery-wrap">
                                {!this.state.activeGallery ? 
                                    <GalleryList handleClick={this.showGallery} gallery={items}/>: 
                                    <GalleryDetail handleClick={this.toggleModal} gallery={items} visibleCount={this.state.visible}/>}

                                

                                {/* {
                                    items.slice(0, this.state.visible).map((gallery, index) => 
                                        <div className="gallery-block fade-in" key={index} >
                                            <div className="gallery-inner">
                                                <a className="gallery-img" onClick={() => this.toggleModal(index)}>
                                                    <figure>
                                                        <div className="img" style={{ backgroundImage: `url(${gallery.src})` }}></div> 
                                                    </figure>
                                                </a>
                                            </div>
                                        </div>
                                    )
                                } */}
                            </div>
                            <div className="more-wrap text-center">
                                {this.state.visible < items[0].gallery.length &&
                                    <a href="#" className="more"  onClick={this.loadMore}>Load More</a>
                                }
                            </div>
                        </div>
                    </div>
                </section>
                <ModalGateway>
                    {modalIsOpen &&  activId ? (
                    <Modal onClose={this.toggleModal}>
                        <Carousel views={items[0].gallery.slice(0, this.state.visible)} currentIndex={selectedIndex}/>
                    </Modal>
                    ) : null}
                </ModalGateway>
            </Layout>
        )
    }
}

export default Gallery
