import React from "react"
import { Link } from "gatsby"

const Gallery = (props) => {
    const items = props.gallery[0];
    return(
        <>
            {
                items.gallery.slice(0, props.visibleCount).map((img, index) => {
                    return (<div className="gallery-block fade-in" key={index} >
                        <div className="gallery-inner">
                            <a className="gallery-img" onClick={() => props.handleClick(index)}>
                                <figure>
                                    <div className="img" style={{ backgroundImage: `url(${img.src})` }}></div> 
                                </figure>
                            </a>
                        </div>
                    </div>)}
                )
            }
        </>
    );
}
export default Gallery;